/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddStoreInput = {|
  address: string,
  allocationLimit: number,
  bdmEmails?: ?$ReadOnlyArray<?InputBdmEmails>,
  branchCode?: ?string,
  canHusqvarnaOpe?: ?boolean,
  canPickup: boolean,
  city: string,
  content?: ?string,
  country: string,
  description?: ?string,
  distributionHub: boolean,
  emails: $ReadOnlyArray<?string>,
  excludeAllocation?: ?InputExcludeAllocation,
  excludeBulkyGood?: ?boolean,
  fax?: ?string,
  hours: InputStoreHour,
  inCallPool: boolean,
  lat: number,
  lng: number,
  managerEmails: $ReadOnlyArray<?string>,
  metaDescription?: ?string,
  metaTitle?: ?string,
  name: string,
  phone: string,
  postcode: string,
  region?: ?string,
  regional: boolean,
  staffDelivery?: ?boolean,
  state: string,
  status: boolean,
|};
export type InputBdmEmails = {|
  brandId?: ?string,
  emails?: ?$ReadOnlyArray<?string>,
|};
export type InputExcludeAllocation = {|
  brands?: ?$ReadOnlyArray<?string>,
  fromTo?: ?$ReadOnlyArray<?string>,
|};
export type InputStoreHour = {|
  fridayClose?: ?string,
  fridayOpen?: ?string,
  mondayClose?: ?string,
  mondayOpen?: ?string,
  saturdayClose?: ?string,
  saturdayOpen?: ?string,
  sundayClose?: ?string,
  sundayOpen?: ?string,
  thursdayClose?: ?string,
  thursdayOpen?: ?string,
  tuesdayClose?: ?string,
  tuesdayOpen?: ?string,
  wednesdayClose?: ?string,
  wednesdayOpen?: ?string,
|};
export type AddStoreMutationVariables = {|
  input: AddStoreInput
|};
export type AddStoreMutationResponse = {|
  +addStore: ?{|
    +storeEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +address: ?string,
        +city: ?string,
        +postcode: ?string,
        +state: ?string,
        +country: ?{|
          +alpha2: ?string,
          +name: ?string,
        |},
        +description: ?string,
        +fax: ?string,
        +phone: ?string,
        +managerEmails: ?$ReadOnlyArray<?string>,
        +bdmEmails: ?$ReadOnlyArray<?{|
          +brandId: ?string,
          +emails: ?$ReadOnlyArray<?string>,
        |}>,
        +emails: ?$ReadOnlyArray<?string>,
        +stateManager: ?$ReadOnlyArray<?{|
          +emails: ?$ReadOnlyArray<?string>
        |}>,
        +lat: ?number,
        +lng: ?number,
        +status: ?boolean,
        +regional: ?boolean,
        +canPickup: ?boolean,
        +excludeBulkyGood: ?boolean,
        +inCallPool: ?boolean,
        +distributionHub: ?boolean,
        +staffDelivery: ?boolean,
        +allocationLimit: ?number,
        +excludeAllocation: ?{|
          +brands: ?$ReadOnlyArray<?{|
            +id: string,
            +name: ?string,
          |}>,
          +startDate: ?string,
          +endDate: ?string,
        |},
        +metaTitle: ?string,
        +metaDescription: ?string,
        +branchCode: ?string,
        +content: ?string,
        +canHusqvarnaOpe: ?boolean,
        +hours: ?{|
          +monday: ?{|
            +open: ?string,
            +close: ?string,
          |},
          +tuesday: ?{|
            +open: ?string,
            +close: ?string,
          |},
          +wednesday: ?{|
            +open: ?string,
            +close: ?string,
          |},
          +thursday: ?{|
            +open: ?string,
            +close: ?string,
          |},
          +friday: ?{|
            +open: ?string,
            +close: ?string,
          |},
          +saturday: ?{|
            +open: ?string,
            +close: ?string,
          |},
          +sunday: ?{|
            +open: ?string,
            +close: ?string,
          |},
        |},
      |},
    |}
  |}
|};
export type AddStoreMutation = {|
  variables: AddStoreMutationVariables,
  response: AddStoreMutationResponse,
|};
*/


/*
mutation AddStoreMutation(
  $input: AddStoreInput!
) {
  addStore(input: $input) {
    storeEdge {
      cursor
      node {
        id
        name
        address
        city
        postcode
        state
        country {
          alpha2
          name
        }
        description
        fax
        phone
        managerEmails
        bdmEmails {
          brandId
          emails
        }
        emails
        stateManager {
          emails
          id
        }
        lat
        lng
        status
        regional
        canPickup
        excludeBulkyGood
        inCallPool
        distributionHub
        staffDelivery
        allocationLimit
        excludeAllocation {
          brands {
            id
            name
          }
          startDate
          endDate
        }
        metaTitle
        metaDescription
        branchCode
        content
        canHusqvarnaOpe
        hours {
          monday {
            open
            close
          }
          tuesday {
            open
            close
          }
          wednesday {
            open
            close
          }
          thursday {
            open
            close
          }
          friday {
            open
            close
          }
          saturday {
            open
            close
          }
          sunday {
            open
            close
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postcode",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "country",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alpha2",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fax",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "managerEmails",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emails",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "BdmEmails",
  "kind": "LinkedField",
  "name": "bdmEmails",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandId",
      "storageKey": null
    },
    (v14/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lat",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lng",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "regional",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canPickup",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "excludeBulkyGood",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inCallPool",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "distributionHub",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "staffDelivery",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocationLimit",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "ExcludeAllocation",
  "kind": "LinkedField",
  "name": "excludeAllocation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Brand",
      "kind": "LinkedField",
      "name": "brands",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaTitle",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaDescription",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "branchCode",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canHusqvarnaOpe",
  "storageKey": null
},
v32 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "open",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "close",
    "storageKey": null
  }
],
v33 = {
  "alias": null,
  "args": null,
  "concreteType": "StoreHour",
  "kind": "LinkedField",
  "name": "hours",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessHour",
      "kind": "LinkedField",
      "name": "monday",
      "plural": false,
      "selections": (v32/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessHour",
      "kind": "LinkedField",
      "name": "tuesday",
      "plural": false,
      "selections": (v32/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessHour",
      "kind": "LinkedField",
      "name": "wednesday",
      "plural": false,
      "selections": (v32/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessHour",
      "kind": "LinkedField",
      "name": "thursday",
      "plural": false,
      "selections": (v32/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessHour",
      "kind": "LinkedField",
      "name": "friday",
      "plural": false,
      "selections": (v32/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessHour",
      "kind": "LinkedField",
      "name": "saturday",
      "plural": false,
      "selections": (v32/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessHour",
      "kind": "LinkedField",
      "name": "sunday",
      "plural": false,
      "selections": (v32/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddStoreMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddStorePayload",
        "kind": "LinkedField",
        "name": "addStore",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreEdge",
            "kind": "LinkedField",
            "name": "storeEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Store",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v15/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StateManager",
                    "kind": "LinkedField",
                    "name": "stateManager",
                    "plural": true,
                    "selections": [
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/),
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v31/*: any*/),
                  (v33/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddStoreMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddStorePayload",
        "kind": "LinkedField",
        "name": "addStore",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreEdge",
            "kind": "LinkedField",
            "name": "storeEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Store",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v15/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StateManager",
                    "kind": "LinkedField",
                    "name": "stateManager",
                    "plural": true,
                    "selections": [
                      (v14/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/),
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v31/*: any*/),
                  (v33/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9e9510f209379bd6f9b5d1933c471839",
    "id": null,
    "metadata": {},
    "name": "AddStoreMutation",
    "operationKind": "mutation",
    "text": "mutation AddStoreMutation(\n  $input: AddStoreInput!\n) {\n  addStore(input: $input) {\n    storeEdge {\n      cursor\n      node {\n        id\n        name\n        address\n        city\n        postcode\n        state\n        country {\n          alpha2\n          name\n        }\n        description\n        fax\n        phone\n        managerEmails\n        bdmEmails {\n          brandId\n          emails\n        }\n        emails\n        stateManager {\n          emails\n          id\n        }\n        lat\n        lng\n        status\n        regional\n        canPickup\n        excludeBulkyGood\n        inCallPool\n        distributionHub\n        staffDelivery\n        allocationLimit\n        excludeAllocation {\n          brands {\n            id\n            name\n          }\n          startDate\n          endDate\n        }\n        metaTitle\n        metaDescription\n        branchCode\n        content\n        canHusqvarnaOpe\n        hours {\n          monday {\n            open\n            close\n          }\n          tuesday {\n            open\n            close\n          }\n          wednesday {\n            open\n            close\n          }\n          thursday {\n            open\n            close\n          }\n          friday {\n            open\n            close\n          }\n          saturday {\n            open\n            close\n          }\n          sunday {\n            open\n            close\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8df63b0e7f062ec3c9854ea5718de9ca';

module.exports = node;
