import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';

import { CreateAndUpdateStateManagerMutation } from './mutations';
import StateManagerForm, { ALL_STORES } from './StateManagerForm';

class StateManagerNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (values, state) => {
    const formValues = {stateManagers: values?.stateManagers.map((s) => ({
      ...s,
      stores: s.stores.includes(ALL_STORES) ? [] : s.stores,
      state,
    }))};

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: formValues },
      viewer: this.props.viewer,
      onCompleted: () => {
        window.location.href = `/store/state-manager/${state}`;
      },
    };

    CreateAndUpdateStateManagerMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New State Manager" />

        <h1>New State Manager</h1>
        <StateManagerForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(StateManagerNew, {
  viewer: graphql`
    fragment StateManagerNew_viewer on Admin {
      stateManagers(first: 1) {
        states
      }
      id
      stores(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      roles(first: 99){
        edges {
          node {
            name
          }
        }
      }
    }
  `,
});
