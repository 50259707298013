import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get, groupBy } from 'lodash';

import { Affix, Button, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Switch, Tabs, Tree, Upload } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { DictatesAttribute, FormBase, fileValidator, formItemLayout, imageValidator } from '~/components/form';
import Presence from '~/components/Presence';
import { UrlRefresher } from '~/components/url';
import SubcategoryHistory from './SubcategoryHistory';

import SubcategoryDescription from './SubcategoryDescription';
import SubcategoryMetadata from './SubcategoryMetadata';
import { CopyToNzBtn, Loading } from '../product/WebsiteRef';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const categoryGroups =[{
  name: "Main",
  autoExpanded: true,
  nameChecker: ({ featured, includeInMenu }) => featured && includeInMenu
}, {
  name: "Second",
  autoExpanded: true,
  nameChecker: ({ featured, includeInMenu }) => !featured && includeInMenu
}, {
  name: "Others",
  autoExpanded: false
}];

const AttributeOptions = (props) => {
  const { attributes, watchField, ...restProps } = props;

  const form = Form.useFormInstance();
  const attributeId = Form.useWatch(watchField, form);

  let options = [];

  if (attributeId) {
    const asdf = attributes.find(({ node }) => node.id === attributeId);
    options = get(asdf, 'node.options', []);
  }

  return (
    <FormItem {...restProps}>
      <Select placeholder="Option" optionLabelProp="children" >
        {options.map(o => <Option key={o}>{o}</Option>)}
      </Select>
    </FormItem>
  )
};

AttributeOptions.propTypes = {
  watchField: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  ).isRequired,
  attributes: PropTypes.arrayOf(
    PropTypes.shape({})
  ).isRequired,
};

const FormTree = (props) => {
  const form = Form.useFormInstance();
  const { attributes, value: { categoryIds, treeNode } } = props;

  const attributesOptions = attributes.map(({ node }) => (
    <Option key={node.id} opts={node.options}>{node.name}</Option>
  ));

  const onTreeNodeCheck = (checkedKeys) => {
    // checked keys may contains group names
    const invalidKeys = categoryGroups.map(g => g.name);
    const filteredKeys = checkedKeys.filter(k => !invalidKeys.includes(k));

    form.setFieldsValue({ autoAssignCategory: { categoryIds: filteredKeys } });
  };

  const defaultExpandedKeys = categoryGroups.filter(({ autoExpanded }) => autoExpanded).map(({ name }) => name);

  const treeData = treeNode.map(({ type, children }, index) => {
    return {
      key: type,
      checkable: false,
      title: (<b>{type} Categories</b>),
      children: children.map((c, i) => ({
        key: c.id,
        title: (
          <>
            {c.name} <b>when </b>
            <div style={{ display: 'inline-block' }}>
              <FormItem
                name={["autoAssignCategory", "treeNode", index, "children", i, "attributeId"]}
                style={{ display: 'inline-block', marginBottom: '0px', width: 150 }}
              >
                <Select
                  showSearch
                  onChange={() => {
                    form.setFieldValue(["autoAssignCategory", "treeNode", index, "children", i, "option"], null)
                  }}
                  placeholder="Attribute"
                  optionLabelProp="children"
                  optionFilterProp="children"
                >
                  {attributesOptions}
                </Select>
              </FormItem>
              &nbsp; is &nbsp;
              <AttributeOptions
                name={["autoAssignCategory", "treeNode", index, "children", i, "option"]}
                style={{ display: 'inline-block', marginBottom: '0px', width: 150 }}
                watchField={["autoAssignCategory", "treeNode", index, "children", i, "attributeId"]}
                attributes={attributes}
              />
            </div>
          </>
        ),
      })),
    }
  });

  return (
    <Tree
      checkedKeys={categoryIds}
      checkable
      multiple
      onCheck={onTreeNodeCheck}
      defaultExpandedKeys={defaultExpandedKeys}
      treeData={treeData}
    />
  )
};

FormTree.propTypes = {
  value: PropTypes.shape({
    categoryIds: PropTypes.arrayOf(PropTypes.string),
    treeNode: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  attributes: PropTypes.arrayOf(
    PropTypes.shape({})
  ).isRequired,
};

FormTree.defaultProps = {
  value: {
    categoryIds: [],
    treeNode: [],
  },
};

class SubcategoryForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      attributes: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    entity: PropTypes.shape({
      id: PropTypes.string
    }),
    onSubmit: PropTypes.func.isRequired,
    remove: PropTypes.func,
  }

  static defaultProps = {
    entity: {},
    match: {},
    remove: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      redirectTo: undefined,
    };

    this.formRef = React.createRef();
  }

  render() {
    const { entity: subcategory, match, viewer } = this.props;
    const images = get(subcategory, 'images.edges', []);
    const attributes = get(viewer, 'attributes.edges', []);
    const brands = get(viewer, 'brands.edges', []);
    const categories = get(viewer, 'categories.edges', []);
    const subcategories = get(viewer, 'allSubcategories.edges', []).filter(({node}) => node.id !== subcategory.id);

    const selectedAttributes = get(subcategory, 'attributes', []);
    const selectedAttributeIds = selectedAttributes.map(attr => attr.id);

    const autoAssignCat = get(subcategory, 'autoAssignCategory') || [];
    const autoCat = autoAssignCat.map(a => a.categoryId);
    const childSubcategories = get(subcategory, 'childSubcategories.edges', []);

    const groupedCats = groupBy(categories, ({ node }) =>
      (categoryGroups.find(({ nameChecker }) => nameChecker ? nameChecker(node) : true) || {}).name
    );

    const imageDefaultValue = {
      subcategoryIds: [],
      categoryIds: [],
      brandIds: [],
    };

    const initialValue = {
      attr: selectedAttributes.map((s) => {
        const attribute = attributes.find(({ node }) => node.id === s.id);
        const name = get(attribute, 'node.name');

        return { ...s, name };
      }),
      images: images.length ? images.slice(0).map(({ node }) => ({
        id: node.id,
        urlSlug: node.urlSlug,
        subcategoryIds: get(node, 'subcategories', []).map(p => ({ key: p.id, label: p.name })),
        categoryIds: get(node, 'categories', []).map(p => ({ key: p.id, label: p.name })),
        brandIds: get(node, 'brands', []).map(p => ({ key: p.id, label: p.name })),
        mode: node.mode,
        link: node.link,
      })) : [imageDefaultValue],
      autoAssignCategory: {
        categoryIds: autoCat,
        treeNode: categoryGroups.filter(({ name: type }) => groupedCats[type] && groupedCats[type].length)
        .map(({ name }) => ({
          type: name,
          children: groupedCats[name].map(({ node }) => {
            const { attributeId, option } = autoAssignCat.find(({ categoryId }) => categoryId === node.id) || {};

            return {
              id: node.id,
              name: node.name,
              attributeId,
              option,
            }
          }),
        })),
      },
    };

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()} style={{ marginRight: '10px' }}>Save</Button>
            {subcategory.id && (
              <React.Suspense fallback={<Loading />}>
                <CopyToNzBtn viewer={viewer} entity={subcategory} />
              </React.Suspense>
            )}
            {subcategory.id && (
            <Popconfirm
              title={
                <div>
                  <p>Are you sure to delete this subcategory?</p>
                  Redirect to
                  <Select
                    showSearch
                    allowClear
                    onChange={(subcatId) => {
                      this.setState({redirectTo: subcatId});
                    }}
                    style={{width: "300px"}}
                    placeholder="Redirect to a subcategory"
                    optionLabelProp="children"
                    optionFilterProp="children"
                  >
                    {subcategories.map(({ node: o }) => <Option key={o.id} value={o.id}>{o.name}</Option>)}
                  </Select>
                </div>
              }
              onConfirm={() => {
                this.props.remove(subcategory, { redirectTo: this.state.redirectTo });
              }}
              okText="Yes"
              cancelText="No"
            >

              <Button disabled={this.shouldDisableBtn()}>Delete</Button>
            </Popconfirm>
            )}
          </div>
        </Affix>

        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            <FormItem
              name="id"
              initialValue={subcategory.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Name"
              name="name"
              rules={[{ required: true, message: 'required' }]}
              initialValue={subcategory.name}
            >
              <Input
                placeholder="Name"
                onChange={(e) => {
                  if (!subcategory.id) {
                    const urlSlug = e.target.value;
                    this.formRef.current.setFieldsValue({ urlSlug });
                  }
                }}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="URL"
            >
              <div>
                <Input placeholder="URL" value={get(subcategory, 'urlSlug')} readOnly />
                <UrlRefresher entity={subcategory} viewer={viewer} />
              </div>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Position"
              name="position"
              rules={[{ required: false, message: 'required' }]}
              initialValue={subcategory.position}
            >
              <InputNumber placeholder="Position" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Search Aliases"
              name="aliases"
              initialValue={subcategory.aliases || []}
            >
              <Select placeholder="Aliases" mode="tags">
                {
                  (subcategory.aliases || []).map(name => <Option key={name} value={name}>{name}</Option>)
                }
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Meta Title"
              name="metaTitle"
              initialValue={subcategory.metaTitle}
            >
              <TextArea rows={2} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Meta Description"
              name="metaDescription"
              initialValue={subcategory.metaDescription}
            >
              <TextArea rows={3} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Show Only On Categories"
              name="categoryIds"
              rules={[{ required: false, message: 'required' }]}
              initialValue={get(subcategory, 'categories', []).map(p => ({
                key: p.id,
                label: p.name,
              }))}
            >
              <Select
                placeholder="Categories"
                mode="multiple"
                optionFilterProp="children"
                optionLabelProp="children"
                labelInValue
              >
                {categories.map(({ node: o }) => <Option key={o.id} value={o.id}>{o.name}</Option>)}
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Nest In Categories"
              name="nests"
              rules={[{ required: false, message: 'required' }]}
              initialValue={get(subcategory, 'nests', []).map(p => ({
                key: p.id,
                label: p.name,
              }))}
              extra="Subcategory will NOT show directly under these categories"
            >
              <Select
                placeholder="Categories"
                mode="multiple"
                optionFilterProp="children"
                optionLabelProp="children"
                labelInValue
              >
                {categories.map(({ node: o }) => <Option key={o.id} value={o.id}>{o.name}</Option>)}
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Nest In Brands"
              name="nestBrands"
              initialValue={get(subcategory, 'nestBrands', []).map(p => ({
                key: p.id,
                label: p.name,
              }))}
              extra="Subcategory will NOT show directly under these brands"
            >
              <Select
                placeholder="Brands"
                mode="multiple"
                optionFilterProp="children"
                optionLabelProp="children"
                labelInValue
              >
                {brands.map(({ node: o }) => <Option key={o.id} value={o.id}>{o.name}</Option>)}
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Parents"
              name="parents"
              rules={[{ required: false, message: 'required' }]}
              initialValue={get(subcategory, 'parents', []).map(p => ({
                key: p.id,
                label: p.name,
              }))}
            >
              <Select
                placeholder="Parent Subcategories"
                mode="multiple"
                optionFilterProp="children"
                optionLabelProp="children"
                labelInValue
              >
                {subcategories.map(({ node: o }) => <Option key={o.id} value={o.id}>{o.name}</Option>)}
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Visible Only Directly Under Categories"
              name="visibleFirstDepthOnly"
              valuePropName="checked"
              initialValue={get(subcategory, 'visibleFirstDepthOnly', false)}
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Display Mode"
              name="displayMode"
              initialValue={get(subcategory, 'displayMode', 'product')}
            >
              <Select placeholder="Status">
                <Option value="product">Product</Option>
                <Option value="subcategory">Subcategory</Option>
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Show Selected Subcategories Only"
              name="subcategoryIds"
              rules={[{ required: false, message: 'required' }]}
              initialValue={get(subcategory, 'subcategories', []).map(p => ({
                key: p.id,
                label: p.name,
              }))}
              extra="These selected subcategories will only show under this subcategory"
            >
              <Select
                placeholder="Subcategories"
                mode="multiple"
                optionFilterProp="children"
                optionLabelProp="children"
                labelInValue
              >
                {childSubcategories.map(({ node: o }) => <Option key={o.id} value={o.id}>{o.name}</Option>)}
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
              name="status"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(subcategory, 'status') ? 1 : 0}
            >
              <Select placeholder="Status">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>

            <Divider />

            <FormItem
              {...formItemLayout}
              label="Dictates Attributes"
            >
              <FormItem
                name="attributes"
                rules={[{ required: false, message: 'required' }]}
                initialValue={selectedAttributeIds}
              >
                <Select
                  optionFilterProp="children"
                  optionLabelProp="children"
                  placeholder="Attributes"
                  mode="multiple"
                  onSelect={(value) => {
                    const attr = this.formRef.current.getFieldValue("attr") || [];
                    const attribute = attributes.find(({ node }) => node.id === value);
                    const name = get(attribute, 'node.name');

                    this.formRef.current.setFieldValue("attr", [...attr, {
                      id: value,
                      name,
                      options: undefined,
                      require: false,
                    }]);
                  }}
                  onChange={(values) => {
                    const attr = this.formRef.current.getFieldValue("attr") || [];

                    if (values.length < attr.length) {
                      this.formRef.current.setFieldValue("attr", attr.filter(({ id }) => values.includes(id)));
                    }
                  }}
                >
                  {
                    attributes.map((edge) => {
                      const o = edge.node;
                      return <Option key={o.id} value={o.id}>{o.name}</Option>;
                    })
                  }
                </Select>
              </FormItem>

              <Form.List name="attr" initialValue={initialValue.attr}>
                {(fields) => fields.map(({ key, ...restField }) => (
                  <DictatesAttribute
                    key={key}
                    parentNamePath={["attr"]}
                    attributes={attributes}
                    {...restField}
                    divider
                  />
                ))}
              </Form.List>
            </FormItem>
          </TabPane>

          <TabPane tab="Images" key="images" forceRender>
            <FormItem
              {...formItemLayout}
              label="Subcategory Images"
            >
              <Form.List name="images" initialValue={initialValue.images}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }, index) => (
                      <div key={key}>
                        <FormItem noStyle shouldUpdate>
                          {({ getFieldValue }) => getFieldValue(["images", name, "id"]) ? (
                            <FormItem
                              name={[name, "id"]}
                              rules={[{ required: true, message: 'required' }]}
                              hidden
                            >
                              <Input />
                            </FormItem>
                          ) : null}
                        </FormItem>

                        <Row>
                          <Col md={8}>
                            <FormItem
                              label={`Image ${index}`}
                            >
                              <FormItem noStyle shouldUpdate>
                                {({ getFieldValue }) => {
                                  const urlSlug = getFieldValue(["images", name, "urlSlug"]);
                                  return urlSlug ? (
                                    <img alt="" src={urlSlug} width="120" height="120" />
                                  ) : null
                                }}
                              </FormItem>

                              {fields.length > 1 ? (
                                <MinusCircleOutlined
                                  style={{ cursor: 'pointer' }}
                                  disabled={fields.length === 1}
                                  onClick={() => remove(name)}
                                />
                              ) : null}

                              <FormItem
                                name={[name, "file"]}
                                valuePropName="fileList"
                                getValueFromEvent={(e) => {
                                  if (Array.isArray(e)) {
                                    return e;
                                  }
                                  return e && e.fileList;
                                }}
                                rules={[
                                  ({ getFieldValue }) => {
                                    const urlSlug = getFieldValue(["images", name, "urlSlug"]);
                                    return { required: !urlSlug,  message: 'required' }
                                  },
                                  { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1) },
                                  { required: true, message: 'Image size should be either 240x240 or 340x170', validator: imageValidator.bind(this, [[240, 240], [340, 170]]) },
                                  ({ getFieldValue }) => ({
                                    required: true,
                                    validator: () => {
                                      const hasDefault = getFieldValue('images')
                                        .find(({ brandIds, categoryIds, subcategoryIds }) => brandIds.length === 0 && categoryIds.length === 0 && subcategoryIds.length === 0);

                                      if (hasDefault) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error('A default image with no brands, categories and subcategories selected is required'));
                                    }
                                  }),
                                ]}
                              >
                                <Upload
                                  accept="image/gif,image/png,image/jpeg"
                                  beforeUpload={() => false}
                                  listType="picture"
                                >
                                  <Button>
                                    <UploadOutlined /> Upload
                                  </Button>
                                </Upload>
                              </FormItem>
                            </FormItem>
                          </Col>
                          <Col md={16}>
                            <FormItem
                              label="On Subcategories"
                              name={[name, "subcategoryIds"]}
                              rules={[{ required: false, message: 'required' }]}
                              style={{ margin: '0px' }}
                            >
                              <Select
                                placeholder="Subcategories"
                                mode="multiple"
                                optionFilterProp="children"
                                optionLabelProp="children"
                                labelInValue
                              >
                                {
                                  subcategories.map(({ node }) => <Option key={node.id} value={node.id}>{node.name}</Option>)
                                }
                              </Select>
                            </FormItem>

                            <FormItem
                              label="On Categories"
                              name={[name, "categoryIds"]}
                              rules={[{ required: false, message: 'required' }]}
                              style={{ margin: '0px' }}
                            >
                              <Select
                                placeholder="Categories"
                                mode="multiple"
                                optionFilterProp="children"
                                optionLabelProp="children"
                                labelInValue
                              >
                                {
                                  categories.map((catEdge) => {
                                    const o = catEdge.node;
                                    return <Option key={o.id} value={o.id}>{o.name}</Option>;
                                  })
                                }
                              </Select>
                            </FormItem>

                            <FormItem
                              label="On Brands"
                              name={[name, "brandIds"]}
                              rules={[{ required: false, message: 'required' }]}
                              style={{ margin: '0px' }}
                            >
                              <Select
                                placeholder="Brands"
                                mode="multiple"
                                optionFilterProp="children"
                                optionLabelProp="children"
                                labelInValue
                              >
                                {
                                  brands.map((brandEdge) => {
                                    const o = brandEdge.node;
                                    return <Option key={o.id} value={o.id}>{o.name}</Option>;
                                  })
                                }
                              </Select>
                            </FormItem>

                            <FormItem
                              label="Mode"
                              name={[name, "mode"]}
                              rules={[{ required: false, message: 'required' }]}
                              style={{ margin: '0px' }}
                            >
                              <Select
                                placeholder="Mode"
                              >
                                <Option value={null}>Default</Option>
                                <Option value={1}>Wide</Option>
                              </Select>
                            </FormItem>

                            <FormItem
                              label="Link"
                              style={{ margin: '0px' }}
                              extra="This subcategory will points to the above link, if specified. Relative link only"
                            >
                              <FormItem name={[name, "link"]}>
                                <Input placeholder="Link" />
                              </FormItem>
                            </FormItem>
                          </Col>
                        </Row>
                        <Divider />
                      </div>
                    ))}

                    <Button onClick={() => add(imageDefaultValue)} >
                      <PlusOutlined />
                    </Button>
                  </>
                )}
              </Form.List>
            </FormItem>
          </TabPane>

          <TabPane tab="Auto Assign" key="auto-assign" forceRender>
            <FormItem
              {...formItemLayout}
              label="Auto Assign to Categories"
            >
              <FormItem
                name="autoAssignCategory"
                initialValue={initialValue.autoAssignCategory}
              >
                <FormTree attributes={attributes} />
              </FormItem>
            </FormItem>
          </TabPane>

          {subcategory.id && (
          <TabPane tab="Description" key="description" forceRender>
            <SubcategoryDescription viewer={viewer} subcategory={subcategory} />
          </TabPane>
          )}

          {subcategory.id && (
            <TabPane tab="Meta Data" key="metadata" forceRender>
              <SubcategoryMetadata viewer={viewer} subcategory={subcategory} />
            </TabPane>
          )}

          {subcategory.id && (
            <TabPane tab="History" key="history">
              <SubcategoryHistory viewer={viewer} subcategory={subcategory} />
            </TabPane>
          )}
        </Tabs>
      </Form>
    );
  }
}

export default createFragmentContainer(SubcategoryForm, {
  viewer: graphql`
    fragment SubcategoryForm_viewer on Admin {
      id
      ...SubcategoryHistory_viewer
      ...UrlRefresher_viewer
      ...SubcategoryDescription_viewer
      ...SubcategoryMetadata_viewer
      attributes(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            code
            options
          }
        }
      }
      brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            featured
            includeInMenu
          }
        }
      }
      allSubcategories: subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
