import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation CreateAndUpdateStateManagerMutation($input: CreateAndUpdateStateManagerInput!) {
    createAndUpdateStateManager(input:$input) {
      stateManagers {
        id
        emails
        state
        areaManagers {
          id
          email
          stores {
            id
          }
        }
        stateManagerAssistants {
          email
          roles
        }
        stores {
          id
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
