import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { Link } from 'found';
import { Helmet } from 'react-helmet';
import { Button, Divider } from 'antd';

import { BooleanDropdownFilter, Grid, iDGetter, valueGetter } from '../grid';
import { fromGlobalId } from '../../helper';

const entityName = 'Subcategory';

class SubcategoryList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      subcategories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    this.columnDefs = [
      {
        headerName: 'Action',
        width: 90,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        cellRendererFramework: (params) => {
          if (params.data) {
            return <Link to={`/subcategory/${fromGlobalId(params.data.node.id).id}`}>Edit</Link>;
          }

          return null;
        },
      },
      {
        colId: 'id', headerName: 'ID', valueGetter: iDGetter.bind(this, 'id'), suppressMenu: true, sortable: true, filter: false,
      },
      {
        colId: 'name', headerName: 'Name', valueGetter: valueGetter.bind(this, 'name'), width: 200,
      },
      {
        colId: 'productCount', headerName: 'Product Count', valueGetter: valueGetter.bind(this, 'productCount'), width: 120, filter: false,
      },
      {
        colId: 'urlSlug', headerName: 'URL', valueGetter: valueGetter.bind(this, 'urlSlug'), width: 200,
      },
      {
        colId: 'position', headerName: 'Position', valueGetter: valueGetter.bind(this, 'position'), width: 200, filter: 'agNumberColumnFilter'
      },
      { colId: 'status', headerName: 'Status', valueGetter: valueGetter.bind(this, 'status'), ...BooleanDropdownFilter },
    ];

    this.datasource = Grid.getDataSource.call(this, 'subcategories');
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <a href="#" onClick={() => { this.props.router.go(-1); }}>Back</a>
        }
        { children }
        <Divider />
        <h1>{entityName}</h1>
        <Link to="/subcategory/new"><Button>Add Subcategory</Button></Link>
        <Grid
          columnDefs={this.columnDefs}
          datasource={this.datasource}
        />
      </div>
    );
  }
}

export default createPaginationContainer(
  SubcategoryList, {
    viewer: graphql`
    fragment SubcategoryList_viewer on Admin {
      subcategories(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy,
      ) @connection(key: "SubcategoryList_subcategories") {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            name
            urlSlug
            position
            productCount
            status
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.subcategories;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { filterBy, orderBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query SubcategoryListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...SubcategoryList_viewer
      }
    }
  `,
  },
);
