import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Affix, Button, Col, Divider, Form, Input, Row, Select, Popconfirm } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FormBase, formItemLayout, formItemLayoutWithOutLabel, getStates } from '~/components/form';
import Presence from '~/components/Presence';
import { validateEmail, checkEmails } from '~/helper';

const { Item: FormItem } = Form;

export const ALL_STORES = "All Stores";

const groupedStoreOptions = (stores) => (
  Object.entries(
    stores.reduce((acc, { node: store }) => {
      const {state} = store;
      if (!acc[state]) acc[state] = [];
      acc[state].push({ label: store.name, value: store.id });
      return acc;
    }, {})
  ).map(([state, options]) => ({
    label: <span>{state}</span>,
    title: state,
    options,
  }))
);

class StateManagerForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      stateManagers: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    const stateManager = get(this.props.viewer, 'stateManagers.edges[0].node', undefined);
    this.disableState = !!stateManager?.state;

    this.state = {
      state: stateManager?.state,
    }
  }

  shouldDisableField = (roles) => {
    return roles.some(({ node }) => node.name === 'State Manager');
  }

  render() {
    const { match, viewer } = this.props;
    const stores = get(viewer, 'stores.edges', []);

    const { state } = this.state;
    const roles = get(viewer, 'roles.edges', []);
    const disableField = this.shouldDisableField(roles);

    const stateManager = get(viewer, 'stateManagers.edges', []);
    const assignedStates = get(viewer, 'stateManagers.states', []);

    const initialValue = {
      stateManagers: stateManager.length > 0 ? stateManager.map(({node: s}) => {
        const {stateManagerAssistants, ...restFields} = s;

        const areaManagers = get(s, 'areaManagers', []).map((areaManager) => {
          const { stores: _stores, ...rest } = areaManager;
          return { ...rest, stores: _stores.map(({ id }) => id) };
        });

        const rawStores = get(s, 'stores', []);
        const smStores = rawStores.length ? rawStores.map(store => store.id) : [ALL_STORES];

        const dispatchers = stateManagerAssistants?.map((dispatcher) => {
          return dispatcher?.roles.includes("Dispatch Assistant") ? dispatcher?.email : undefined
        }).filter(email => email !== undefined);

        return {
          ...restFields,
          emails: get(s, "emails[0]", undefined),
          dispatchAssistants: dispatchers,
          areaManagers,
          stores: smStores,
        }
      }) : [{}]
    };

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(values, state); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
          </div>
        </Affix>

        <Form.List
          name="stateManagers"
          initialValue={initialValue.stateManagers}
        >
          {(stateManagerFields, { add: addStateManager, remove: removeStateManager }) => (
            <>
              {stateManagerFields.map(({ key: stateManagerKey, name: stateManagerName }) => (
                <React.Fragment key={stateManagerKey}>
                  <FormItem
                    name={[stateManagerName, "id"]}
                    hidden
                  >
                    <Input />
                  </FormItem>

                  <FormItem {...formItemLayout} label="Emails">
                    <Row align="middle" gutter={8}>
                      <Col flex="auto">
                        <FormItem
                          name={[stateManagerName, "emails"]}
                          noStyle
                          rules={[
                            { required: true, message: 'required' },
                            {
                              validator: (_, value) => {
                                if (value && !validateEmail(value)) {
                                  return Promise.reject(new Error('invalid email'));
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input type="email" placeholder="Email" allowClear />
                        </FormItem>
                      </Col>

                      <Col>
                        <Popconfirm
                          title="Are you sure you want to delete this state manager group including related store, dispatch assistants and area managers?"
                          onConfirm={() => removeStateManager(stateManagerName)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <MinusCircleOutlined
                            style={{ color: 'red', fontSize: 18, cursor: 'pointer' }}
                          />
                        </Popconfirm>
                      </Col>
                    </Row>
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="State"
                    rules={[{ required: true, message: 'required' }]}
                  >
                    <Select
                      placeholder="State"
                      options={Object.entries(getStates()).map(([k, v]) => ({ value: k, label: v })).filter(({value}) => !assignedStates.includes(value))}
                      value={state}
                      disabled={this.disableState || disableField}
                      onChange={(value) => {this.setState({state: value})}}
                    />
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="Stores"
                    name={[stateManagerName, "stores"]}
                    rules={[{ required: true, message: 'required' }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="label"
                      mode="multiple"
                      placeholder="Stores"
                      style={{ width: '100%' }}
                      options={[
                        { value: ALL_STORES, label: `${ALL_STORES} in ${state}` },
                        ...groupedStoreOptions(stores),
                      ]}
                    />
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="Dispatch Assistants"
                    name={[stateManagerName, "dispatchAssistants"]}
                    rules={[
                      { required: false, validator: checkEmails }
                    ]}
                  >
                    <Select
                      mode="tags"
                      style={{ width: '100%' }}
                      placeholder="Dispatch Assistants"
                      disabled={disableField}
                    />
                  </FormItem>

                  <Form.List
                    name={[stateManagerName, "areaManagers"]}
                    initialValue={initialValue.stateManagers.areaManagers}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name }, index) => {
                          const formStyle = {
                            emailCol: { xs: 24, sm: 10 },
                            storesCol: { xs: 24, sm: 12 },
                          };

                          return (
                            <React.Fragment key={key}>
                              <FormItem
                                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                label={index === 0 ? 'Area Managers' : ''}
                                required={false}
                              >
                                <Row>
                                  <Col {...formStyle.emailCol}>
                                    <FormItem
                                      name={[name, "id"]}
                                      hidden
                                    >
                                      <Input />
                                    </FormItem>

                                    <FormItem
                                      name={[name, "email"]}
                                      rules={[
                                        { required: true, message: 'required' },
                                        {
                                          required: true, validator: (rule, value) => {
                                            if (value && !validateEmail(value)) {
                                              return Promise.reject(new Error('invalid email'));
                                            }
                                            return Promise.resolve();
                                          }
                                        },
                                      ]}
                                    >
                                      <Input type="email" placeholder="Email" allowClear />
                                    </FormItem>
                                  </Col>

                                  <Col {...formStyle.storesCol}>
                                    <FormItem
                                      name={[name, "stores"]}
                                      rules={[
                                        { required: true, message: 'required' },
                                        { validator: (rule, value) => {
                                          if (Array.isArray(value) && value.length > 10) {
                                            return Promise.reject(new Error('Cannot have more than 10 stores'));
                                          }
                                          return Promise.resolve();
                                        }},
                                      ]}
                                      style={{ display: 'inline-block', width: 'calc(100% - 24px)' }}
                                    >
                                      <Select
                                        allowClear
                                        showSearch
                                        optionFilterProp="label"
                                        mode="multiple"
                                        placeholder="Stores"
                                        style={{ width: '100%' }}
                                        options={groupedStoreOptions(stores)}
                                        onSelect={(value) => {
                                          const smStorePath = ["stateManagers", stateManagerName, "stores"];
                                          const smStores = this.formRef.current.getFieldValue(smStorePath) || [];

                                          if (!smStores.includes(ALL_STORES)) {
                                            this.formRef.current.setFieldValue(smStorePath, [...smStores, value]);
                                          }
                                        }}
                                      />
                                    </FormItem>

                                    <MinusCircleOutlined
                                      style={{ marginLeft: '10px' }}
                                      onClick={() => remove(name)}
                                    />
                                  </Col>
                                </Row>
                              </FormItem>
                            </React.Fragment>
                          )
                        })}

                        <FormItem {...formItemLayoutWithOutLabel}>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: '60%' }}
                            icon={<PlusOutlined />}
                          >
                            Add Area Managers
                          </Button>
                        </FormItem>

                        <Divider />
                      </>
                    )}
                  </Form.List>
                </React.Fragment>
              ))}

              <Button
                onClick={() => addStateManager()}
                style={{ marginRight: '5px' }}
                icon={<PlusOutlined />}
              >
                Add State Manager Group
              </Button>
            </>
          )}
        </Form.List>
      </Form>
    )
  }
}
export default StateManagerForm;
