/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateAndUpdateStateManagerInput = {|
  stateManagers?: ?$ReadOnlyArray<?InputStateManager>
|};
export type InputStateManager = {|
  areaManagers?: ?$ReadOnlyArray<?InputAreaManager>,
  dispatchAssistants?: ?$ReadOnlyArray<?string>,
  emails?: ?$ReadOnlyArray<?string>,
  id?: ?string,
  state: string,
  stores?: ?$ReadOnlyArray<?string>,
|};
export type InputAreaManager = {|
  email: string,
  id?: ?string,
  stores: $ReadOnlyArray<?string>,
|};
export type CreateAndUpdateStateManagerMutationVariables = {|
  input: CreateAndUpdateStateManagerInput
|};
export type CreateAndUpdateStateManagerMutationResponse = {|
  +createAndUpdateStateManager: ?{|
    +stateManagers: ?$ReadOnlyArray<?{|
      +id: string,
      +emails: ?$ReadOnlyArray<?string>,
      +state: ?string,
      +areaManagers: ?$ReadOnlyArray<?{|
        +id: string,
        +email: ?string,
        +stores: ?$ReadOnlyArray<?{|
          +id: string
        |}>,
      |}>,
      +stateManagerAssistants: ?$ReadOnlyArray<?{|
        +email: ?string,
        +roles: ?$ReadOnlyArray<?string>,
      |}>,
      +stores: ?$ReadOnlyArray<?{|
        +id: string
      |}>,
    |}>
  |}
|};
export type CreateAndUpdateStateManagerMutation = {|
  variables: CreateAndUpdateStateManagerMutationVariables,
  response: CreateAndUpdateStateManagerMutationResponse,
|};
*/


/*
mutation CreateAndUpdateStateManagerMutation(
  $input: CreateAndUpdateStateManagerInput!
) {
  createAndUpdateStateManager(input: $input) {
    stateManagers {
      id
      emails
      state
      areaManagers {
        id
        email
        stores {
          id
        }
      }
      stateManagerAssistants {
        email
        roles
        id
      }
      stores {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emails",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Store",
  "kind": "LinkedField",
  "name": "stores",
  "plural": true,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AreaManager",
  "kind": "LinkedField",
  "name": "areaManagers",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roles",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAndUpdateStateManagerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAndUpdateStateManagerPayload",
        "kind": "LinkedField",
        "name": "createAndUpdateStateManager",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StateManager",
            "kind": "LinkedField",
            "name": "stateManagers",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StateManagerAssistant",
                "kind": "LinkedField",
                "name": "stateManagerAssistants",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateAndUpdateStateManagerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAndUpdateStateManagerPayload",
        "kind": "LinkedField",
        "name": "createAndUpdateStateManager",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StateManager",
            "kind": "LinkedField",
            "name": "stateManagers",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StateManagerAssistant",
                "kind": "LinkedField",
                "name": "stateManagerAssistants",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "50e0af6e0c4f507d5f9459cd73e88112",
    "id": null,
    "metadata": {},
    "name": "CreateAndUpdateStateManagerMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAndUpdateStateManagerMutation(\n  $input: CreateAndUpdateStateManagerInput!\n) {\n  createAndUpdateStateManager(input: $input) {\n    stateManagers {\n      id\n      emails\n      state\n      areaManagers {\n        id\n        email\n        stores {\n          id\n        }\n      }\n      stateManagerAssistants {\n        email\n        roles\n        id\n      }\n      stores {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f931d53859b93375b3fddf3394e711d8';

module.exports = node;
