import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { Link } from 'found';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';
import { Button, Divider } from 'antd';
import { Grid, dateGetter, valueGetter } from '~/components/grid';

const entityName = 'State Manager';

class StateManagerList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      stateManagers: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    this.columnDefs = [
      {
        headerName: 'Action',
        width: 90,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        cellRendererFramework: (params) => {
          if (params.data) {
            return <Link to={`/store/state-manager/${params.data.node.state}`}>Edit</Link>;
          }

          return null;
        },
      },
      {
        colId: 'emails',
        headerName: 'Emails',
        valueGetter: valueGetter.bind(this, 'emails'),
        cellRenderer: params => get(params, 'value', []).join("<br/>"),
        filter: false,
        width: 200,
      },
      { colId: 'state', headerName: 'State', valueGetter: valueGetter.bind(this, 'state') },
      { colId: 'insertedAt', headerName: 'Created At', valueGetter: dateGetter.bind(this, 'insertedAt'), filter: false },
    ];

    this.datasource = Grid.getDataSource.call(this, 'stateManagers', null, this.groupDatabyState);
  }

  groupDatabyState = (data) => {
    const grouped = data.reduce((acc, { cursor, node }) => {
      const { state, emails, insertedAt } = node;
      if (!acc[state]) {
          acc[state] = {
              cursor,
              node: {
                  emails: [],
                  insertedAt,
                  state
              }
          };
      }
      acc[state].node.emails = [...new Set([...acc[state].node.emails, ...emails])];
      acc[state].node.insertedAt = new Date(acc[state].node.insertedAt) < new Date(insertedAt) ? acc[state].node.insertedAt : insertedAt;
      return acc;
    }, {});

    return Object.values(grouped);
  }

  render() {
    const { children } = this.props;
    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <a href="#" onClick={() => { this.props.router.go(-1); }}>Back</a>
        }
        { children }
        <Divider />
        <h1>{entityName}</h1>
        <Link to="/store/state-manager/new"><Button>Add {entityName}</Button></Link>
        <Grid
          columnDefs={this.columnDefs}
          datasource={this.datasource}
        />
      </div>
    )
  }
}

export default createPaginationContainer(
  StateManagerList, {
    viewer: graphql`
    fragment StateManagerList_viewer on Admin {
      stateManagers(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy
      ) @connection(key: "StateManagerList_stateManagers") {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            emails
            state
            insertedAt
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.stateManagers;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { filterBy, orderBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query StateManagerListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...StateManagerList_viewer
      }
    }
  `,
  },
);
