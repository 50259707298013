import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';
import { CreateAndUpdateStateManagerMutation } from './mutations';

import StateManagerForm, { ALL_STORES } from './StateManagerForm';

class StateManagerView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      stateManagers: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  handleSubmit = (values, state) => {
    const formValues = {stateManagers: values?.stateManagers.map((s) => ({
      ...s,
      stores: s.stores.includes(ALL_STORES) ? [] : s.stores,
      state,
    }))};

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: formValues },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        window.location.reload();
      },
    };
    CreateAndUpdateStateManagerMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const stateManager = get(viewer, 'stateManagers.edges[0].node', {});

    return (
      <div>
        <Helmet title={stateManager.email} />
        <h1>{stateManager.email}</h1>
        <StateManagerForm
          key={stateManager.id}
          match={match}
          viewer={viewer}
          onSubmit={this.handleSubmit}
        />
      </div>
    )
  }
}

export default createFragmentContainer(StateManagerView, {
  viewer: graphql`
    fragment StateManagerView_viewer on Admin {
      stateManagers(first: 999, filterBy: $filterBy) {
        edges {
          node {
            id
            emails
            state
            areaManagers {
              id
              email
              stores {
                id
              }
            }
            stateManagerAssistants {
              email
              roles
            }
            stores {
              id
            }
          }
        }
      }
      stores(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            state
          }
        }
      }
      roles(first: 99){
        edges {
          node {
            name
          }
        }
      }
    }
  `
});
