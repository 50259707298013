import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';
import { getPageSize } from '~/components/grid';

const routes = (
  <Route
    path="/state-manager"
    getComponent={() => (
      import(/* webpackChunkName: "StateManagerList" */'./StateManagerList').then(module => module.default)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={graphql`
      query routes_StateManagerList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
        viewer {
          ...StateManagerList_viewer
        }
      }
    `}
    prepareVariables={() => ({
      count: getPageSize(),
      orderBy: { field: 'state', direction: 'asc' },
    })}
  >
    <Route />

    <Route
      path="/new"
      getComponent={() => (
        import(/* webpackChunkName: "StateManagerNew" */'./StateManagerNew').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_StateManagerNew_Query {
          viewer {
            ...StateManagerNew_viewer
          }
        }
      `}
    />

    <Route
      path="/:state"
      getComponent={() => (
        import(/* webpackChunkName: "StateManagerView" */'./StateManagerView').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_StateManagerView_Query ($filterBy: [FilterBy]) {
          viewer {
            ...StateManagerView_viewer
          }
        }
      `}
      prepareVariables={(params) => {
        const {state} = params;
        return { filterBy: [{
          field: "state",
          filter: state,
          filterType: "text",
          type: "equals",
        }] };
      }}
    />
  </Route>
);

export default routes;
