import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get, isEmpty } from 'lodash';
import { Button, message, Select, Switch, Table, Tabs, Row, Col, Checkbox } from 'antd';
import moment from 'moment-timezone';
import { DatePicker } from '~/components/form';
import { getRoles } from '~/helper';
import { CurrencyFormatter, PercentageFormatter, TIMEZONE } from './helper';
import { getCellColor } from './RevenueReport';
import "./style.css";

const { Option } = Select;
const { TabPane } = Tabs;

const entityName = 'Sales Target Report';

const ROWSTYLES = {
  1: 'ant-alert-success',
  0: 'ant-alert-error',
};

class SalesTargetReport extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      salesTargetReport: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
          ])
        ),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      params: PropTypes.shape({
      }).isRequired,
      route: PropTypes.shape({
        prepareVariables: PropTypes.func.isRequired,
      }).isRequired
    }).isRequired,
  }

  constructor(props) {
    super(props);

    const { match, viewer } = this.props;
    const tableSource = get(this.props.viewer, 'salesTargetReport.data', []);
    const { filterBy, isMgr } = match.route.prepareVariables();
    const [ { filter: dateFilter }] = filterBy;
    const initDateRange = dateFilter.split(',').map(d => moment(d));

    this.dateRange = initDateRange;
    this.isMgr = isMgr;
    this.fetchedDateRange = '';
    this.dateRangePickerRef = React.createRef();

    const { filter: branches } = filterBy.find(item => item.field === 'branches') || {};
    this.branchCodeMgr = (branches || "").split(",").filter(i => i);
    const allStores = get(viewer, 'stores.edges', []);
    this.stores = this.branchCodeMgr.length > 0 ? allStores.filter(({ node }) => this.branchCodeMgr.includes(node.branchCode)) : allStores;

    const allStaff = get(viewer, "staff", []);
    this.staff= this.branchCodeMgr.length > 0 ? allStaff.filter(member => this.branchCodeMgr.includes(member.branch_code)) : allStaff;

    const roles = getRoles();
    this.isAreaManager = roles.some(({ name }) => name === 'Area Manager');
    this.isStateManager = roles.some(({ name }) => name === 'State Manager');

    this.state = {
      activeKey: "Store",
      loading: false,
      locations: [],
      states: [],
      staff: [],
      areaManagers: [],
      stateManagers: [],
      tableSource,
      currentDates: null,
      showInactStaff: false,
      expandedRowKeys: [],
      year: initDateRange[0],
      selectedColumns: ['sales', 'target', 'pct', 'diff'],
      smExpandedRowKeys: []
    };
  }

  onDownload = () => {
    const dlVariables = JSON.stringify(this.prepareVariables());
    const downloadUrl = `/api/report/sales-target/download?filters=${encodeURIComponent(dlVariables)}`;
    window.open(downloadUrl, '_blank', 'noopener,noreferrer');
  };

  onDateChange = (dateRange) => {
    this.dateRange = dateRange;
  }

  onClickThisMonth = () => {
    const end = moment().subtract(1, 'days').endOf('day');
    const start = moment().startOf('month');
    this.dateRange = [start, end];

    if (this.dateRangePickerRef.current) {
      this.dateRangePickerRef.current.blur();
    }

    this.refetch(this.dateRange);
  }

  onCurrentDateChange = (currentDates) => {
    this.setState({currentDates});
  }

  onLocationChane = ( locations ) => {
    this.setState({ locations, tableSource: [] }, () => { this.refetch() });
  }

  onStateChange = ( states ) => {
    this.setState({ states, tableSource: [] }, () => { this.refetch() });
  }

  onOperChange = ( staff ) => {
    this.setState({ staff, tableSource: [] }, () => { this.refetch() });
  }

  onAreaManagerChange = ( areaManagers ) => {
    this.setState({ areaManagers, tableSource: [] }, () => { this.refetch() });
  }

  onStateManagerChange = ( stateManagers ) => {
    this.setState({ stateManagers, tableSource: [] }, () => { this.refetch() });
  }

  onInactStaffChange = ( value ) => {
    this.setState({ showInactStaff: value });
  }

  onYearChange = ( year ) => {
    this.setState({ year }, () => { this.refetch() })
  }

  onCheckboxChange = ( selectedColumns ) => {
    this.setState({ selectedColumns })
  }

  getClassName = (prevCol) => {
    const { selectedColumns } = this.state;
    return prevCol.some(col => selectedColumns.includes(col)) ? "border-b-bold" : "border-l-bold"
  }

  disableDate = (current) => {
    const { currentDates } = this.state;

    const mgrLimit = () => {
      if (this.isMgr) {
        const today = moment();
        const minDate = today.clone().subtract(90, 'days');
        if (current.isBefore(minDate) || current.isAfter(today)) {
          return true;
        }
      }
      return false;
    }

    if ( currentDates ) {
      const from = currentDates[0];
      const to = currentDates[1];

      if (from) {
        const endOfMonth = from.clone().endOf('month');
        return current.isBefore(from) || current.isAfter(endOfMonth) || mgrLimit();
      } else if (to) {
        const startOfMonth = to.clone().startOf('month');
        return current.isBefore(startOfMonth) || current.isAfter(to) || mgrLimit();
      }
    }

    return mgrLimit();
  };

  SalesmanColumns = () => {
    const titles = [...new Set(get(this.props.viewer, 'salesTargetReport.data', []).map(item => item.title))];

    return (
      [
        {
          title: 'Staff Name',
          dataIndex: 'staff_name',
          key: 'staff_name',
          sorter: (a, b) => a.staff_name.localeCompare(b.staff_name),
        },
        {
          title: 'Oper',
          dataIndex: 'oper',
          key: 'oper',
          sorter: (a, b) => a.oper.localeCompare(b.oper),
        },
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
          sorter: (a, b) => (a.title ?? "").localeCompare(b.title ?? ""),
          filters: titles.map(title => ({
            text: title,
            value: title,
          })),
          onFilter: (value, record) => record.title === value,
        },
        {
          title: 'Sales',
          dataIndex: 'revenue',
          key: 'revenue',
          render: sales => (
            <div>
              <CurrencyFormatter amount={sales} />
            </div>
          ),
          sorter: (a, b) => parseFloat(a.revenue) - parseFloat(b.revenue),
          className: "ant-alert-warning"
        },
        {
          title: 'No. of Invoice',
          dataIndex: 'no_of_invoice',
          key: 'no_of_invoice',
          sorter: (a, b) => parseFloat(a.no_of_invoice) - parseFloat(b.no_of_invoice),
          className: "ant-alert-warning"
        },
        {
          title: 'Average Ticket Price',
          dataIndex: 'average_ticket_price',
          key: 'average_ticket_price',
          render: average => (
            <div>
              <CurrencyFormatter amount={average} />
            </div>
          ),
          sorter: (a, b) => parseFloat(a.average_ticket_price) - parseFloat(b.average_ticket_price),
          className: "ant-alert-warning"
        },
        {
          title: 'Branch Name',
          dataIndex: 'branch_name',
          key: 'branch_name',
          sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
        },
        {
          title: 'Date Started',
          dataIndex: 'start_date',
          key: 'start_date',
          sorter: (a, b) => a.start_date.localeCompare(b.start_date),
        },
        {
          title: 'Days Worked',
          dataIndex: 'days_worked',
          key: 'days_worked',
          sorter: (a, b) => parseFloat(a.days_worked) - parseFloat(b.days_worked),
        },
        {
          title: 'Daily Target',
          dataIndex: 'daily_target',
          key: 'daily_target',
          render: dailyTarget => (
            <div>
              <CurrencyFormatter amount={dailyTarget} />
            </div>
          ),
          sorter: (a, b) => parseFloat(a.daily_target) - parseFloat(b.daily_target),
        },
        {
          title: 'Daily Average',
          dataIndex: 'daily_average',
          key: 'daily_average',
          render: monthlyTarget => (
            <div>
              <CurrencyFormatter amount={monthlyTarget} />
            </div>
          ),
          sorter: (a, b) => parseFloat(a.daily_average) - parseFloat(b.daily_average),
        },
        {
          title: 'Percentage',
          dataIndex: 'pct',
          key: 'pct',
          render: text => typeof text === 'number' ? `${(parseFloat(text) * 100).toFixed(2)}%` : '0%',
          sorter: (a, b) => parseFloat(a.pct) - parseFloat(b.pct),
        },
        {
          title: 'Year_Month',
          dataIndex: 'year_month',
          key: 'year_month',
          sorter: (a, b) => a.year_month.localeCompare(b.year_month),
        },
        {
          title: 'State',
          dataIndex: 'state',
          key: 'state',
          sorter: (a, b) => a.state.localeCompare(b.state),
        },
        {
          title: 'Meet',
          dataIndex: 'meet',
          key: 'meet',
          sorter: (a, b) => parseFloat(a.meet) - parseFloat(b.meet),
        },
      ]
    )
  };

  StoreColumn = () => {
    const filteredTitles = [
      'Year_Month',
      'No. of Invoice',
      'Branch Name',
      'State',
      'Daily Target',
      'Daily Average',
      'Sales',
      'Percentage',
      'Meet'
    ];

    return this.SalesmanColumns()
      .filter(column => filteredTitles.includes(column.title))
      .map(column => {
        if (column.title === 'Branch Name') {
          return {
            ...column,
            render: (branchName, record) => (
              <div>
                <Button
                  style={{
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    margin: 0,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: '#cb0000',
                  }}
                  onClick={() => {
                    this.setState({
                      locations: [record.branch_code],
                      activeKey: "Salesman"
                    }, () => { this.refetch(); });
                  }}
                >
                  {branchName}
                </Button>
              </div>
            )
          };
        }
        return column;
      });
  }

  ManagerColumn = () => {
    const filteredTitles = [
      'Year_Month',
      'Daily Target',
      'Daily Average',
      'Sales',
      'Percentage',
    ];

    const columns = this.SalesmanColumns().filter(column =>
      filteredTitles.includes(column.title)
    );

    columns.unshift(
      {
        title: 'Email',
        dataIndex: 'manager',
        key: 'manager',
        sorter: (a, b) => a.area_manager.localeCompare(b.area_manager),
      }
    );

    return columns;
  };

  storeSummaryColumn = (data, selectedColumns) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const noOfColumns = isEmpty(data) ? 0 : data[0].length;
    const colPerMonth = (noOfColumns - 3) / 12;

    const storeSummaryColumns = months.map((month, monthIndex) => {
      const i = monthIndex + 1;

      const columns = [];

      if (selectedColumns.includes('sales')) {
        columns.push({
          title: <div style={{ textAlign: 'center' }}>Sales</div>,
          dataIndex: (i - 1) * colPerMonth + 1,
          key: `${i}sales`,
          className: "border-l-bold",
          render: (value, record) => {
            const diff = record[(i - 1) * colPerMonth + 4];
            return (
              <div className={getCellColor(diff)}>
                <CurrencyFormatter amount={value} />
              </div>
            );
          },
          sorter: (a, b) => (a[(i - 1) * colPerMonth + 1] || 0) - (b[(i - 1) * colPerMonth + 1] || 0),
          width: 80,
        });
      }

      if (selectedColumns.includes('target')) {
        columns.push({
          title: <div style={{ textAlign: 'center' }}>Target</div>,
          dataIndex: (i - 1) * colPerMonth + 2,
          key: `${i}target`,
          className: this.getClassName(['sales']),
          render: (value, record) => {
            const diff = record[(i - 1) * colPerMonth + 4];
            const totalTarget = record[noOfColumns - 1];

            return (
              <div className={getCellColor(diff)} style={{textAlign: 'center'}}>
                <CurrencyFormatter amount={value} />
                <PercentageFormatter numerator={value} denominator={totalTarget} />
              </div>
            );
          },
          sorter: (a, b) => (a[(i - 1) * colPerMonth + 2] || 0) - (b[(i - 1) * colPerMonth + 2] || 0),
          width: 80,
        });
      }

      if (selectedColumns.includes('pct')) {
        columns.push({
          title: <div style={{ textAlign: 'center' }}>Pct</div>,
          dataIndex: (i - 1) * colPerMonth + 3,
          key: `${i}pct`,
          render: (value, record) => {
            const diff = record[(i - 1) * colPerMonth + 4];
            return(
              <span className={getCellColor(diff)}>
                {value === 'NA' ?  'NA' : `${(value * 100).toFixed(1)}%`}
              </span>
            )
          },
          sorter: (a, b) => (a[(i - 1) * colPerMonth + 3] || 0) - (b[(i - 1) * colPerMonth + 3] || 0),
          className: this.getClassName(['sales', 'target']),
          width: 60,
        });
      }

      if (selectedColumns.includes('diff')) {
        columns.push({
          title: <div style={{ textAlign: 'center' }}>Diff</div>,
          dataIndex: (i - 1) * colPerMonth + 4,
          key: `${i}diff`,
          render: (value) => (
            <span className={getCellColor(value)}>
              <CurrencyFormatter amount={value} />
            </span>
          ),
          sorter: (a, b) => (a[(i - 1) * colPerMonth + 4] || 0) - (b[(i - 1) * colPerMonth + 4] || 0),
          className: this.getClassName(['sales', 'target', 'pct']),
          width: 80,
        });
      }

      return {
        title: <div style={{ textAlign: 'center' }}>{month}</div>,
        children: columns,
        className: "border-l-bold",
      };
    });

    storeSummaryColumns.unshift({
      title: 'Store',
      dataIndex: 0,
      key: "store_summary_name",
      sorter: (a, b) => a[0] - b[0],
      render: (value, record) => {
        const totalTarget = record[noOfColumns - 1];

        return (
          <div style={{textAlign: 'right'}}>
            <div>{value}</div>
            <div>(<CurrencyFormatter amount={totalTarget} />)</div>
          </div>
        )
      },
      onHeaderCell: () => ({ style: { width: 150, minWidth: 150 } }),
      fixed: 'left',
    });

    storeSummaryColumns.push({
      title: 'Total Sales',
      dataIndex: noOfColumns - 2,
      key: "sum",
      sorter: (a, b) => a[noOfColumns - 1] - b[noOfColumns - 1],
      className: "border-l-bold",
      render: (value) => <CurrencyFormatter amount={value} />,
    });

    return storeSummaryColumns;
  };

  expandedRowRender = (record) => {
    const nestedColumns = this.StoreColumn().filter(column => column.title !== 'Year_Month');

    nestedColumns.unshift(
      {
        title: 'From',
        dataIndex: 'effective_start',
        key: 'effective_start',
        sorter: (a, b) => a.effective_start.localeCompare(b.effective_start),
      },
      {
        title: 'To',
        dataIndex: 'effective_end',
        key: 'effective_end',
        sorter: (a, b) => a.effective_end.localeCompare(b.effective_end),
      }
    )

    return (
      <Table
        dataSource={record?.branch_data?.map((item, index) => ({ ...item, key: index }))}
        columns={nestedColumns}
        pagination={false}
        rowClassName={(branchData) => {
          return ROWSTYLES[branchData.meet]
        }}
      />
    );
  };

  prepareVariables = () => {
    const { locations, states, activeKey, staff, areaManagers, year, stateManagers } = this.state;
    const dateRangeFilter = [moment(this.dateRange[0]).startOf('day').utc().format(), moment(this.dateRange[1]).endOf('day').utc().format()].join(",");
    const filterBy = [
      {
        field: "inserted_at",
        filter: dateRangeFilter,
        filterType: "text",
        type: "inRange",
      },
    ];

    if (locations.length > 0) {
      filterBy.push({
        field: "branches",
        filter: locations.join(','),
        filterType: "text",
        type: "equals",
      })
    } else if (this.isMgr) {
      filterBy.push({
        field: "branches",
        filter: this.branchCodeMgr.join(','),
        filterType: "text",
        type: "equals",
      })
    };

    if (states.length > 0) {
      filterBy.push({
        field: "states",
        filter: states.join(','),
        filterType: "text",
        type: "equals",
      })
    };

    if ( year ) {
      filterBy.push({
        field: "year",
        filter: year,
        filterType: "text",
        type: "equals",
      })
    }

    if (areaManagers.length > 0) {
      filterBy.push({
        field: "AreaManagers",
        filter: areaManagers.join(','),
        filterType: "text",
        type: "equals",
      })
    };

    if (stateManagers.length > 0) {
      filterBy.push({
        field: "StateManagers",
        filter: stateManagers.join(','),
        filterType: "text",
        type: "equals",
      })
    };

    if (staff.length > 0) {
      filterBy.push({
        field: "staff",
        filter: staff.join(','),
        filterType: "text",
        type: "equals",
      })
    }

    return {filterBy, tab: activeKey};
  }

  refetch = (date) => {
    if (this.fetchedDateRange && this.fetchedDateRange === date) {
      return;
    }
    const refetchVariables = this.prepareVariables();

    const loading = true;
    this.setState({loading});

    this.props.relay.refetch(
      refetchVariables,
      null,
      (error)=> {
        if (error) {
          const e = get(error, '[0].message', 'Shit happened');
          message.error(e);
        }
        const tableSource = get(this.props.viewer, 'salesTargetReport.data', []);

        this.fetchedDateRange = date;

        this.setState({
          loading: false,
          tableSource,
        });

      },
      refetchVariables
    );
  }

  renderSelectStates = () => {
    const STATES = () => {
      const country = process.env.COUNTRY;

      if ( country === "NZ" ) {
        return ['NORTH ISLAND', 'SOUTH ISLAND'];
      }

      return ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
    }

    return (
      <Select
        allowClear
        mode="multiple"
        showSearch
        onChange={this.onStateChange}
        value={this.state.states}
        placeholder="Select a state"
        style={{marginLeft: '20px', minWidth: '200px'}}
        disabled={this.state.locations.length>0}
      >
        {STATES().map(node => (
          <Option key={node} value={node}>{node}</Option>
        ))}
      </Select>
    )
  }

  renderSelectStores = () => {
    return (
      <Select
        allowClear
        mode="multiple"
        showSearch
        onChange={this.onLocationChane}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        value={this.state.locations}
        placeholder="Select a store"
        style={{marginLeft: '20px', minWidth: '200px'}}
        disabled={this.state.states.length>0}
      >
        {this.stores.map(({node}) => {
          return <Option key={node.id} value={node.branchCode}>{node.branchCode === '18' ? `${node.name  } Commercial` : node.name}</Option>
        })}
      </Select>
    )
  }

  renderSelectStaff = () => {
    return (
      <Select
        allowClear
        mode="multiple"
        showSearch
        onChange={this.onOperChange}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        value={this.state.staff}
        placeholder="Select a staff"
        style={{marginLeft: '20px', minWidth: '200px'}}
      >
        {this.staff.map((staff) => {
          return <Option key={staff.code} value={staff.code}>{`${staff.name} (${staff.code})`}</Option>
        })}
      </Select>
    )
  }

  renderSelectManager = (managers, onChange, value) => {
    return (
      <Select
        allowClear
        mode="multiple"
        showSearch
        onChange={onChange}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        value={value}
        placeholder="Select a Manager"
        style={{marginLeft: '20px', minWidth: '200px'}}
      >
        {managers.map((manager) => {
          return <Option key={manager} value={manager}>{manager}</Option>
        })}
      </Select>
    )
  }

  renderManagerTable = (expandedRowKeys, onExpandedRowsChange) => {
    const { tableSource, loading } = this.state;

    return (
      <Table
        dataSource={tableSource.map((item, index) => ({ ...item, key: index }))}
        columns={this.ManagerColumn()}
        loading={loading}
        pagination={{ position: "both", pageSize: 100 }}
        scroll={{ x: true }}
        expandable={{
          expandedRowRender: this.expandedRowRender,
          expandedRowKeys,
          onExpandedRowsChange,
        }}
      />
    )
  }

  render() {
    const { activeKey, loading, showInactStaff, tableSource, expandedRowKeys, year, selectedColumns, smExpandedRowKeys } = this.state;
    const stateMgr = get(this.props.viewer, "stateManagers.edges", []);
    const areaManagers = stateMgr.flatMap(({node}) => get(node, "areaManagers", [])).map((am) => am.email);
    const stateManagers = stateMgr.flatMap(({node}) => node.emails);

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        <h1>{entityName}</h1>

        {activeKey === 'StoreSummary' && <DatePicker value={year} picker="year" onChange={this.onYearChange} />}

        {activeKey !== 'StoreSummary' && (
        <DatePicker.RangePicker
          ref={this.dateRangePickerRef}
          onChange={this.onDateChange}
          onCalendarChange={this.onCurrentDateChange}
          disabledDate={this.disableDate}
          value={this.dateRange}
          onOpenChange={open => {
            // if popup panel is closing
            if (!open) {
              this.refetch(this.dateRange);
              this.setState( {currentDates: null} );
            }
          }}
          renderExtraFooter={() => (
            <Button onClick={this.onClickThisMonth}>
              This Month
            </Button>
          )}
          timezone={TIMEZONE}
        />
)}

        {!this.isMgr && this.renderSelectStates()}
        {this.renderSelectStores()}

        {activeKey ==='Salesman' && this.renderSelectStaff()}
        {activeKey ==='AreaManagers' && this.renderSelectManager(areaManagers, this.onAreaManagerChange, this.state.areaManagers)}
        {activeKey ==='StateManagers' && this.renderSelectManager(stateManagers, this.onStateManagerChange, this.state.stateManagers)}

        <Tabs activeKey={activeKey} onChange={(key) => { this.setState({ activeKey: key, tableSource: [] }, () => {this.refetch()}); }}>
          <TabPane tab="Yearly Summary" key="StoreSummary">
            <Row>
              <Col span={24}>
                <Checkbox.Group
                  options={[
                    { label: 'Sales', value: 'sales' },
                    { label: 'Target', value: 'target' },
                    { label: 'Pct', value: 'pct' },
                    { label: 'Diff', value: 'diff' },
                  ]}
                  value={selectedColumns}
                  onChange={this.onCheckboxChange}
                />
              </Col>
            </Row>

            <Table
              dataSource={
                tableSource
                .filter(row => Array.isArray(row))
                .map((row, index) => {
                  const rowData = {};
                  row.forEach((value, i) => {
                    rowData[i] = value;
                  });
                  rowData.key = index;
                  return rowData;
                })
              }
              columns={this.storeSummaryColumn(tableSource, selectedColumns)}
              loading={loading}
              pagination={false}
              scroll={{ x: 'max-content', y: window.innerHeight - 300 }}
              size="small"
            />
          </TabPane>

          <TabPane tab="Store" key="Store">
            <Button style={{ marginBottom: '10px' }} onClick={this.onDownload}>
              Download
            </Button>
            <Table
              dataSource={tableSource.map((item, index) => ({ ...item, key: index }))}
              columns={this.StoreColumn()}
              loading={loading}
              pagination={false}
              scroll={{ x: true }}
              rowClassName={(record) => {
                return ROWSTYLES[record.meet]
              }}
              footer={() => (
                <div style={{ textAlign: 'right', paddingRight: '16px' }}>
                  Total rows: {tableSource.length}
                </div>
              )}
            />
          </TabPane>

          <TabPane tab="Salesman" key="Salesman">
            <Button style={{ marginBottom: '10px' }} onClick={this.onDownload}>
              Download
            </Button>
            <div style={{marginBottom: '10px'}}>Show Inactive Staff: <Switch checked={showInactStaff} onChange={this.onInactStaffChange}  /></div>
            <Table
              dataSource={
                showInactStaff ? tableSource.map((item, index) => ({ ...item, key: index })) : tableSource.filter((item) => (item.days_worked > 0)).map((item, index) => ({ ...item, key: index }))
              }
              columns={this.SalesmanColumns()}
              loading={loading}
              pagination={{ position: "both", pageSize: 100 }}
              scroll={{ x: true }}
              rowClassName={(record) => {
                return ROWSTYLES[record.meet]
              }}
              size="small"
            />
          </TabPane>

          {!this.isAreaManager && (
          <TabPane tab="AreaManagers" key="AreaManagers">
            {this.renderManagerTable(expandedRowKeys, (keys) => this.setState({ expandedRowKeys: keys }))}
          </TabPane>
)}

          {!this.isAreaManager && !this.isStateManager && (
          <TabPane tab="StateManagers" key="StateManagers">
            {this.renderManagerTable(smExpandedRowKeys, (keys) => this.setState({ smExpandedRowKeys: keys }))}
          </TabPane>
)}
        </Tabs>
      </div>
    );
  }
}

export default createRefetchContainer(
    SalesTargetReport, {
    viewer: graphql`
    fragment SalesTargetReport_viewer on Admin {
      salesTargetReport(filterBy: $filterBy, tab: $tab)
      roles(first: 99){
        edges {
          node {
            name
            defaultValues
          }
        }
      }
      stores(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            branchCode
            distributionHub
          }
        }
      }
      staff
      stateManagers(first: 999) {
        edges {
          node {
            emails
            areaManagers {
              email
            }
          }
        }
      }
    }
  `,
  },
  graphql`
    query SalesTargetReportRefetchQuery($filterBy: [FilterBy], $tab: String) {
      viewer {
        salesTargetReport(filterBy: $filterBy, tab: $tab)
      }
    }
  `,
);
