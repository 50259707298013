import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get, isEmpty } from 'lodash';
import { message } from 'antd';
import { RemoveSubcategoryMutation, UpdateSubcategoryMutation } from './mutations';

import SubcategoryForm from './SubcategoryForm';

class SubcategoryView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      subcategories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const uploadables = {};

    const options = formValues.autoAssignCategory.treeNode.flatMap(({ children }) => children);

    const autoAssignCategory = [];
    formValues.autoAssignCategory.categoryIds.forEach((id) => {
      const data = options.find((o) => o.id === id);
      autoAssignCategory.push({
        categoryId: id,
        attributeId: data.attributeId,
        option: data.option,
      })
    });

    const values = {
      ...formValues,
      status: !!formValues.status,
      autoAssignCategory,
      attributes: formValues.attr.map((a) => ({
        id: a.id,
        options: a.options || null,
        required: a.required || false,
      })),
      images: formValues.images.map((image, index) => {
        const i = {
          subcategoryIds: image.subcategoryIds.map(p => p.key),
          categoryIds: image.categoryIds.map(p => p.key),
          brandIds: image.brandIds.map(p => p.key),
          mode: image.mode,
          link: image.link,
        };

        const { id, file } = image;

        if (id) {
          i.id = id;
        }

        if (file) {
          const name = `image${index}`;

          i.file = name;
          uploadables[name] = file[0].originFileObj;
        }

        return i;
      }),
      categoryIds: formValues.categoryIds.map(p => p.key),
      subcategoryIds: formValues.subcategoryIds.map(p => p.key),
      nests: formValues.nests.map(p => p.key),
      nestBrands: formValues.nestBrands.map(p => p.key),
      parents: formValues.parents.map(p => p.key),
      descriptions: formValues.descriptions.map((d) => ({
        ...d,
        categoryIds: d.categoryIds.map(p => p.key),
        brandIds: d.brandIds.map(p => p.key),
      })),
      metadata: formValues.metadata.map((m) => {
        const categoryId = get(m, "canonicalUrl.category.key", null);
        const subcategoryIds = get(m, "canonicalUrl.subcategories", []).filter(id => id !== undefined).map(p => p.key);

        if (categoryId && isEmpty(subcategoryIds)) {
          subcategoryIds.push(formValues.id);
        }

        return {
          ...m,
          canonicalUrl: {categoryId, subcategoryIds},
          categoryIds: m.categoryIds.map(p => p.key),
          brandIds: m.brandIds.map(p => p.key),
        }
      }),
    };

    delete values.attr;

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }
    UpdateSubcategoryMutation.commit(mutation);
  }

  remove = (subcategory, { redirectTo } = {}) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { id: subcategory.id, redirectTo } },
      viewer: this.props.viewer,
      safeGuard: "delete",
      onCompleted: () => {
        window.location.href = "/subcategory";
      },
    };
    RemoveSubcategoryMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const subcategory = get(viewer, 'subcategories.edges[0].node', {});

    return (
      <div>
        <Helmet title={subcategory.name} />
        <h1>{subcategory.name}</h1>
        <SubcategoryForm
          key={subcategory.id}
          match={match}
          viewer={viewer}
          entity={subcategory}
          onSubmit={this.handleSubmit}
          remove={this.remove}
        />
      </div>
    );
  }
}

export default createFragmentContainer(SubcategoryView, {
  viewer: graphql`
    fragment SubcategoryView_viewer on Admin {
      id
      ...SubcategoryForm_viewer
      subcategories(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            urlSlug
            position
            aliases
            attributes
            metaTitle
            metaDescription
            descriptions (first: 999) {
              edges {
                node {
                  id
                  description
                  description2
                  brands {
                    id
                    name
                  }
                  categories {
                    id
                    name
                  }
                }
              }
            }
            metadata (first: 999) {
              edges {
                node {
                  id
                  metaTitle
                  metaDescription
                  canonicalUrl {
                    url
                    status
                    category {
                      id
                      name
                    }
                    subcategories {
                      id
                      name
                    }
                  }
                  brands {
                    id
                    name
                  }
                  categories {
                    id
                    name
                  }
                }
              }
            }
            categories {
              id
              name
            }
            childSubcategories (first: 999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            subcategories {
              id
              name
            }
            autoAssignCategory {
              categoryId
              attributeId
              option
            }
            images (first: 999) {
              edges {
                node {
                  id
                  urlSlug
                  mode
                  link
                  brands {
                    id
                    name
                  }
                  categories {
                    id
                    name
                  }
                  subcategories {
                    id
                    name
                  }
                }
              }
            }
            status
            displayMode
            visibleFirstDepthOnly
            nestBrands {
              id
              name
            }
            nests {
              id
              name
            }
            parents {
              id
              name
            }
            ...SubcategoryHistory_subcategory
            ...WebsiteRef_subcategory
          }
        }
      }
    }
  `,
});
